import React, { useEffect } from 'react';
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

import Layout from '../components/layout';

import { colors } from '../components/colors';
import * as styles from './welcome.module.css';
import * as linkStyles from '../components/link.module.css';
import * as buttonStyles from '../components/button.module.css';
import { refreshSessionId } from '../utils/session_id';

import { RenderHead } from "../components/head";
export const Head = () => RenderHead({ title: 'Welcome' });



const originType = process.env.ORIGIN_TYPE;
const shiftsEmailOnlyOrigin = originType === 'shifts-email-only';



//
// component

const Welcome = ({ location }) => {

    useEffect(() => { refreshSessionId(); }, []);
    
    return (
        <Layout
            location={ location }
            removeHorizontalPadding={ true } >
            
            <div
                style={{
                    margin: 0,
                    display: `flex`,
                    flexDirection: `column`,
                    justifyContent: `center`,
                    alignItems: `center`
                }} >

                <div
                    className={ styles.container }
                    style={{
                        margin: `90px 0 150px`,
                        display: `flex`,
                        flexDirection: `column`,
                        justifyContent: `center`,
                        alignItems: `center`
                    }} >

                    <div
                        name="welcome-title"
                        style={{
                            margin: 0,
                            width: `100%`,
                            fontFamily: `"Overpass", sans-serif`,
                            fontSize: `40px`,
                            fontWeight: `bold`,
                            lineHeight: `1.13`,
                            textAlign: `center`,
                            color: colors.green
                        }} >
                        Congratulations and welcome to 6Pages!
                    </div>

                    <div
                        style={{
                            margin: `10px 0 0`,
                            width: `100%`,
                            fontSize: `15px`,
                            lineHeight: `1.87`,
                            letterSpacing: `0.15px`,
                            textAlign: `center`,
                            color: colors.black
                        }} >
                        
                        <span>
                            Additional details about your plan were sent to your email address. Feel free to reach out with questions or feedback anytime at
                        </span>
                        <OutboundLink
                            href='mailto:hello@6pages.com'
                            className={ linkStyles.link }
                            style={{ margin: `0 1px 0 5px` }} >
                            hello@6pages.com
                        </OutboundLink>
                        <span>
                            , or visit our
                        </span>
                        <Link
                            to={ `/contact` }
                            className={ `${linkStyles.link} ${linkStyles.linkSameWhenVisited}` }
                            style={{ margin: `0 5px 0 5px` }} >
                            Contact Us
                        </Link>
                        <span>
                            page for additional ways to connect.
                        </span>
                    </div>

                    <div
                        style={{
                            margin: `40px 0 0`,
                            width: `100%`,
                            fontSize: `15px`,
                            lineHeight: `1.87`,
                            letterSpacing: `0.15px`,
                            textAlign: `center`,
                            color: colors.black
                        }} >
                        You’re ready to get briefed!
                    </div>

                    <Link
                        to={ `/` }
                        role={ `button` }
                        tabIndex={ 0 }
                        className={ buttonStyles.submit }
                        style={{
                            margin: `20px 0 0`,
                            padding: `14px 51px`
                        }} >
                        { shiftsEmailOnlyOrigin ? "Browse Past Headlines" : "Browse Briefs" }
                    </Link>

                </div>
                
            </div>
            
        </Layout>
    );
};


export default Welcome;
